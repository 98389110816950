.no-data {
  display: flex;
  flex-direction: column;
  height: 8.213333rem;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 0.213333rem;
}

.no-data img {
  display: block;
  width: 4.266667rem;
}

.no-data span {
  font-weight: 500;
  font-size: 0.426667rem;
  color: #A7AAB6;
}