.footer-wrap {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 1.333333rem;
  background-color: #fff;
  box-shadow: inset 0 0.026667rem 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
}

.footer-item {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #A7AAB6;
}

.footer-item img {
  display: block;
  width: 0.533333rem;
  margin-bottom: 0.16rem;
}

.footer-item .foot-active {
  color: #2E303B;
}