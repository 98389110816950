.agent-statistics-wrap {
  margin: 0 0.32rem 0.64rem;
  background: linear-gradient(105.37deg, #6478E2 0%, #699AF7 100%);
  border-radius: 0.213333rem;
  padding: 0.32rem;
}

.agent-statistics-box {
  display: flex;
}

.agent-statistics-item {
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  justify-content: center;
  overflow: hidden;
}

.agent-statistics-item .label {
  margin: 0.213333rem 0;
  font-weight: 500;
  font-size: 0.32rem;
  color: #FFFFFF;
  opacity: 0.8;
}

.agent-statistics-item .money {
  padding-right: 0.266667rem;
  font-weight: bold;
  font-size: 0.48rem;
  color: #FFFFFF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.echarts-wrap {
  padding: 0.32rem 0.32rem 0 0.32rem;
}

.echarts-wrap .echarts-for-react {
  margin-bottom: 0.32rem;
}

.echarts-wrap .echarts-for-react:last-child {
  margin-bottom: 0;
}

.echarts-wrap canvas {
  border-radius: 0.213333rem;
}

.week-detail-wrap {
  padding: 0.32rem 0.32rem 0 0.32rem;
}

.week-detail-box {
  padding: 0.32rem;
  background-color: #fff;
  border-radius: 0.213333rem;
}

.week-detail-box h3 {
  padding: 0.213333rem 0 0.32rem 0;
  font-size: 0.426667rem;
  color: #2E303B;
  font-weight: 500;
}

.detail-table {
  width: 100%;
  border-collapse: collapse;
}

.detail-table thead tr th {
  height: 0.8rem;
  line-height: 0.8rem;
  border: 0.013333rem solid #E3E3E3;
  background: #F7F7FA;
  opacity: 0.6;
}

.detail-table tbody tr td {
  height: 0.8rem;
  line-height: 0.8rem;
  border: 0.013333rem solid #E3E3E3;
  opacity: 0.6;
  font-family: DINPro;
  font-size: 0.32rem;
  text-align: center;
}