.profit-report-wrap {
  margin-top: 2.88rem;
  padding: 0 0.32rem 0.32rem;
}

.profit-report-wrap .profit-report-pagination {
  position: fixed;
  left: 0;
  top: 6.453333rem;
  width: 100%;
  padding: 0.133333rem 0.32rem;
  background-color: #fff;
  z-index: 1;
}

.profit-report-wrap .profit-report-pagination .am-button {
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.373333rem;
}

.profit-report-wrap .profit-report-wrap-wrap {
  padding-top: 1.12rem;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-box {
  padding: 0 0.32rem 0.32rem;
  margin-bottom: 0.32rem;
  background: #FFFFFF;
  border-radius: 0.213333rem;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-box .profit-report-item {
  border-bottom: 0.026667rem solid #E3E3E3;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-box .profit-report-item:last-child {
  border-bottom: none;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-box .profit-report-item h3 {
  padding: 0.533333rem 0 0.32rem;
  font-weight: 500;
  font-size: 0.426667rem;
  color: #2E303B;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-box .profit-report-item .report-info-wrap {
  display: flex;
  justify-content: space-between;
  padding: 0.213333rem 0;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-box .profit-report-item .report-info-wrap .label {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #000000;
  opacity: 0.6;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-box .profit-report-item .report-info-wrap .content {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #000000;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-statistics-wrap {
  margin-top: 0.32rem;
  padding: 0.64rem 0.32rem;
  background: linear-gradient(105.37deg, #6478E2 0%, #699AF7 100%);
  border-radius: 0.213333rem;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-statistics-wrap .statistics-box {
  display: flex;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-statistics-wrap .statistics-box .statistics-item {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-statistics-wrap .statistics-box .statistics-item .label {
  margin: 0.213333rem 0;
  color: #FFFFFF;
  opacity: 0.8;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-statistics-wrap .statistics-box .statistics-item .content {
  margin: 0.213333rem 0;
  color: #FFFFFF;
}

.profit-report-wrap .profit-report-wrap-wrap .profit-report-tip {
  padding-top: 0.213333rem;
  font-weight: 500;
  font-size: 0.32rem;
  color: #000000;
  opacity: 0.5;
  line-height: 0.426667rem;
}