.mydate {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  height: 0.96rem;
  border-radius: 0.213333rem;
}

.mydate .mydate-range-wrap {
  width: 4.4rem;
  height: 0.96rem;
  color: rgba(83, 92, 102, 0.9);
}

.mydate .mydate-range-wrap .mydate-range-list-item.am-list-item {
  height: 0.96rem;
  min-height: unset;
  padding-left: 0.32rem;
  background: #F7F7FA;
  border-radius: 0.213333rem;
}

.mydate .mydate-range-wrap .mydate-range-list-item.am-list-item .am-list-line .am-list-extra {
  flex-basis: unset;
  width: 2.666667rem;
}

.mydate .mydate-range-wrap .mydate-range-list-item.am-list-item .am-list-line .am-list-content {
  color: rgba(83, 92, 102, 0.9);
  font-weight: 500;
  font-size: 0.426667rem;
}

.mydate .mydate-range-wrap.account-type-box {
  width: auto;
}

.mydate .mydate-range-wrap.account-type-box .am-list-item {
  min-height: unset;
  padding-left: 0.32rem;
  background: #F7F7FA;
  border-radius: 0.213333rem;
}

.mydate .mydate-range-wrap.account-type-box .am-list-item .am-list-extra {
  flex-basis: unset;
}

.mydate .mypicker {
  height: 0.96rem;
  background: #F7F7FA;
  border-radius: 0.213333rem;
}

.mydate .mypicker .am-list-item {
  min-height: unset;
  padding-left: 0.32rem;
  background: #F7F7FA;
  border-radius: 0.213333rem;
}

.mydate .mypicker .am-list-item .am-list-line .am-list-extra {
  flex-basis: unset;
  width: 0.933333rem;
}