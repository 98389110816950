.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  font-size: .28rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  -webkit-transform: translateZ(0.026667rem);
  transform: translateZ(0.026667rem);
  background: #1f1f1f;
}

.loading-text {
  border-radius: .1rem;
  padding: .3rem;
  min-width: 1.2rem;
  color: #fff;
  background-color: rgba(58, 58, 58, 0.9);
  line-height: 1.5;
}

.loading-icon {
  width: .72rem;
  height: .72rem;
  -webkit-animation: cirle-anim 1s linear infinite;
  animation: cirle-anim 1s linear infinite;
}

.loading-info {
  margin-top: .12rem;
}

@-webkit-keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes cirle-anim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}