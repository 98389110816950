.general-agent-rebate-record-wrap {
  padding: 1.44rem 0.32rem 0;
}

.general-agent-rebate-record-wrap .general-agent-rebate-record-pagination {
  position: fixed;
  left: 0;
  top: 3.893333rem;
  width: 100%;
  padding: 0.133333rem 0.32rem;
  background-color: #fff;
  z-index: 1;
}

.general-agent-rebate-record-wrap .general-agent-rebate-record-pagination .am-button {
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.373333rem;
}

.general-agent-rebate-record-wrap .general-agent-rebate-record-box {
  margin-bottom: 0.32rem;
  padding: 0 0.32rem 0.32rem;
  background: #FFFFFF;
  border-radius: 0.213333rem;
}

.general-agent-rebate-record-wrap .general-agent-rebate-record-box .general-agent-rebate-record-item {
  border-bottom: 0.026667rem solid #E3E3E3;
}

.general-agent-rebate-record-wrap .general-agent-rebate-record-box .general-agent-rebate-record-item:last-child {
  border-bottom: none;
}

.general-agent-rebate-record-wrap .general-agent-rebate-record-box .general-agent-rebate-record-item h3 {
  padding: 0.533333rem 0 0.32rem;
  font-weight: 500;
  font-size: 0.426667rem;
  color: #2E303B;
}

.general-agent-rebate-record-wrap .general-agent-rebate-record-box .general-agent-rebate-record-item .report-info-wrap {
  display: flex;
  justify-content: space-between;
  padding: 0.213333rem 0;
}

.general-agent-rebate-record-wrap .general-agent-rebate-record-box .general-agent-rebate-record-item .report-info-wrap .label {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #000000;
  opacity: 0.6;
}

.general-agent-rebate-record-wrap .general-agent-rebate-record-box .general-agent-rebate-record-item .report-info-wrap .content {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #000000;
}