.member-recommend-wrap {
  display: flex;
  height: 0.8rem;
  padding-left: 0.32rem;
  background: #F7F7FA;
}

.member-recommend-wrap div {
  display: flex;
  height: 100%;
  margin-right: 0.533333rem;
  align-items: center;
  text-align: center;
  font-size: 0.32rem;
}

.member-recommend-wrap div .label {
  font-weight: 500;
  color: #A7AAB6;
}

.member-recommend-wrap div strong {
  color: #2E303B;
}

.member-recommend-wrap div.recommend-box img {
  margin-left: 0.133333rem;
}