.subordinate-bet-record-wrap {
  padding: 2.56rem 0.32rem 0;
}

.subordinate-bet-record-wrap .subordinate-bet-record-pagination {
  position: fixed;
  left: 0;
  top: 6.346667rem;
  width: 100%;
  padding: 0.133333rem 0.32rem;
  background-color: #fff;
  z-index: 1;
}

.subordinate-bet-record-wrap .subordinate-bet-record-pagination .am-button {
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.373333rem;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box {
  margin-bottom: 0.32rem;
  padding: 0 0.32rem 0.32rem;
  background: #FFFFFF;
  border-radius: 0.213333rem;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item {
  border-bottom: 0.026667rem solid #E3E3E3;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item:last-child {
  border-bottom: none;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item h3 {
  padding: 0.32rem 0 0.133333rem;
  font-weight: 500;
  font-size: 0.426667rem;
  color: #2E303B;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.213333rem 0;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .label {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #000000;
  opacity: 0.6;
  word-break: break-all;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .string-content-img {
  display: inline-block;
  width: 0.48rem;
  height: 0.64rem;
  margin: 0 0.133333rem;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .content {
  max-width: 75%;
  font-weight: 500;
  font-size: 0.373333rem;
  color: #000000;
  word-wrap: break-word;
  text-align: right;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .content.poker-obj-content {
  display: flex;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .content .array-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .content .array-content-item img {
  display: block;
  width: 0.8rem;
  margin: 0.133333rem;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .content .obj-content-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.08rem 0;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .content .obj-content-wrap .label {
  flex: 0 0 60%;
  width: 60%;
  word-break: break-all;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .content .obj-content-item img {
  display: block;
  width: 0.48rem;
  height: 0.64rem;
  margin: 0 0.133333rem;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .content.array-content-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .content.array-content-wrap .array-content-img {
  display: inline-block;
  width: 0.48rem;
  height: 0.64rem;
  margin: 0.133333rem;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .report-info-wrap .egc-html {
  line-height: 0.666667rem;
  font-weight: 500;
  font-size: 0.373333rem;
  color: #000000;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .view-btn-box {
  height: 0.96rem;
  margin-top: 0.133333rem;
}

.subordinate-bet-record-wrap .subordinate-bet-record-box .subordinate-bet-record-item .view-btn-box .am-button {
  height: 0.96rem;
  line-height: 0.96rem;
  font-size: 0.426667rem;
  border-radius: 0.213333rem;
}

.special-betting-rebate-main-wrap .subordinate-bet-record-pagination {
  top: 11.653333rem;
}

.subordinate-detail-model-wrap .am-modal-transparent {
  width: 95%;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body {
  max-height: 80vh;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.026667rem solid #d9d9d9;
  border-left: 0.026667rem solid #d9d9d9;
  border-right: 0.026667rem solid #d9d9d9;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item:first-child {
  border-top: 0.026667rem solid #d9d9d9;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .label {
  display: block;
  width: 33%;
  flex: 0 0 33%;
  padding: 0.133333rem;
  background-color: #e7e7e7;
  word-break: break-all;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .detail-model-content-item-detail {
  flex: 0 0 66%;
  width: 66%;
  padding-right: 0.133333rem;
  word-wrap: break-word;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .detail-model-string-content-img {
  display: inline-block;
  width: 0.48rem;
  height: 0.64rem;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .detail-model-content-item-content .detail-model-content-item-content-obj {
  display: flex;
  align-items: center;
  padding: 0.133333rem 0;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .detail-model-content-item-content .detail-model-content-item-content-obj .obj-content-item img {
  display: block;
  width: 0.48rem;
  height: 0.64rem;
  margin: 0 0.133333rem;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .detail-model-content-item-content-array {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .detail-model-content-item-content-array .array-content-item {
  display: flex;
  align-items: center;
  margin: 0.08rem;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .detail-model-content-item-content-array .array-content-item span:first-child {
  display: flex;
  align-items: center;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .detail-model-content-item-content-array .array-content-item img {
  display: block;
  width: 0.8rem;
  margin: 0 0.133333rem;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-item .detail-model-content-item-content-array .detail-model-array-content-img {
  display: inline-block;
  width: 0.48rem;
  height: 0.64rem;
  margin: 0.08rem;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-title {
  height: 0.853333rem;
  line-height: 0.853333rem;
  border-bottom: 0.026667rem solid #d9d9d9;
  border-left: 0.026667rem solid #d9d9d9;
  border-right: 0.026667rem solid #d9d9d9;
}

.subordinate-detail-model-wrap .am-modal-transparent .am-modal-body .detail-model-content-introduce {
  text-align: left;
}