.personalBox-wrap {
  display: flex;
  flex: 0 0 17%;
  width: 17%;
  align-items: center;
}

.personalBox-wrap img {
  display: block;
  width: 0.64rem;
}