.cash-report-wrap {
  margin-top: 1.28rem;
  padding: 0.32rem 0.32rem 0;
  background: #F7F7FA;
}

.incomings-wrap {
  margin-bottom: 0.32rem;
  padding: 0.32rem;
  background-color: #fff;
  border-radius: 0.213333rem;
}

.incomings-wrap h3 {
  padding: 0.213333rem 0 0.32rem 0;
  font-size: 0.426667rem;
  color: #2E303B;
  font-weight: 500;
}

.incomings-table {
  width: 100%;
  border-collapse: collapse;
}

.incomings-table thead tr th {
  height: 0.8rem;
  line-height: 0.8rem;
  border: 0.013333rem solid #E3E3E3;
  background: #F7F7FA;
  opacity: 0.6;
}

.incomings-table tbody tr td {
  height: 0.8rem;
  line-height: 0.533333rem;
  border: 0.013333rem solid #E3E3E3;
  opacity: 0.6;
  font-family: DINPro;
  font-size: 0.32rem;
  text-align: center;
}